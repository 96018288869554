// Path: packages/flex/src/separator/separator.tsx

import React, { cloneElement } from 'react';
import clsx from 'clsx';
import useMeasure from 'react-use-measure';
import type { SeparatorProperties } from './separator.types';
import { Button } from '../Button';
import { Text } from '../Text';

/**
 *
 * A separator is a thin line that separates content, adding a button to fetch more content.
 * @group Components
 * @figma https://www.figma.com/file/...
 */
export const Separator = ({
  type = 'none',
  buttonProperties,
  onClick,
  icon,
  className,
  children,
}: SeparatorProperties) => {
  const [reference, bounds] = useMeasure();

  return (
    <div className={clsx('relative w-full', className)}>
      <hr
        className="h-px w-full border-none bg-slate-400 bg-slate-600"
        style={
          type === 'light'
            ? {
                clipPath: `polygon(0% 0%, 0% 100%, calc(50% - ${
                  bounds.width / 2
                }px) 100%, calc(50% - ${bounds.width / 2}px) calc(50% - ${
                  bounds.width / 2
                }px), calc(50% + ${bounds.width / 2}px) calc(50% - ${
                  bounds.width / 2
                }px), calc(50% + ${bounds.width / 2}px) calc(50% + ${
                  bounds.width / 2
                }px), calc(50% - ${bounds.width / 2}px) calc(50% + ${
                  bounds.width / 2
                }px), calc(50% + ${bounds.width / 2}px) 100%, 100% 100%, 100% 0%)`,
              }
            : undefined
        }
      />

      <div className="absolute inset-0 grid place-content-center">
        {type === 'button' && (
          <Button
            icon={icon}
            onClick={onClick}
            theme="white"
            size="xs"
            type="button"
            {...buttonProperties}
          >
            {children}
          </Button>
        )}

        {type === 'light' && (
          <button
            className="group flex items-center gap-2 px-2"
            ref={reference}
            onClick={onClick}
            type="button"
          >
            {icon &&
              cloneElement(icon, {
                className:
                  '-ml-0.5 w-4 h-4 text-slate-500 flex-shrink-0 group-hover:text-primary-500 transition-colors',
              })}
            <Text className="group-hover:text-primary-500 transition-colors">{children}</Text>
          </button>
        )}
      </div>
    </div>
  );
};
