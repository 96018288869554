export const defaultTranslationKeys = {
  today: 'Today',
  tomorrow: 'Tomorrow',
  yesterday: 'Yesterday',
  last7Days: 'Last 7 days',
  last30Days: 'Last 30 days',
  thisMonth: 'This month',
  lastMonth: 'Last month',
  lastWeek: 'Last week',
  previousMonth: 'Previous month',
  nextMonth: 'Next month',
  thisYear: 'This year',
  lastYear: 'Last year',
  allTime: 'All time',
  optional: 'Optional',
  selectDate: 'Select a date',
  selectDateRange: 'Select a date range',
  period: 'Period',
  days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  lang: 'en-GB',
};
