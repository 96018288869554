import React from 'react';
import Skeleton from './GenerateSkeleton';

const FilterSkeleton = () => (
  <>
    <Skeleton count={1} height={40} width={150} style={{ marginBottom: 40 }} />
    <Skeleton count={1} height={40} width={170} style={{ marginBottom: 20 }} />
    <Skeleton height={200} width={130} style={{ marginBottom: 20 }} />
    <Skeleton count={1} height={40} width={170} style={{ marginBottom: 20 }} />
    <Skeleton height={200} width={130} style={{ marginBottom: 20 }} />
    <Skeleton count={1} height={40} width={170} style={{ marginBottom: 20 }} />
    <Skeleton height={150} width={130} style={{ marginBottom: 20 }} />
  </>
);

export default FilterSkeleton;
