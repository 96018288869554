// Path: packages/flex/src/dialog/dialog.tsx

import React, { useMemo } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import type { AlertDialogProperties } from './alertdialog.types';
import { Heading } from '../Heading';
import { Button } from '../Button';
import { Text } from '../Text';

const defaultTranslationKeys = {
  cancel: 'Cancel',
};

export type TAlertDialogTranslationKeys = Record<keyof typeof defaultTranslationKeys, string>;

/**
 *
 * An AlertDialog is a dialog that interrupts the user's workflow to communicate an important message and acquire a response.
 * @group Components
 */
export const AlertDialog = ({
  trigger,
  title,
  description,
  action,
  onClose,
  className,
  translations,
  open,
}: AlertDialogProperties) => {
  // merge the default translations with the user's translations if there are any
  const t: typeof defaultTranslationKeys = useMemo(
    () => ({
      ...defaultTranslationKeys,
      ...translations,
    }),
    [translations],
  );

  return (
    <AlertDialogPrimitive.Root
      onOpenChange={(open) => {
        if (!open && onClose) onClose();
      }}
      {...(open === undefined ? {} : { open })}
    >
      {trigger && (
        <AlertDialogPrimitive.Trigger asChild type={undefined}>
          {trigger}
        </AlertDialogPrimitive.Trigger>
      )}
      <AlertDialogPrimitive.Portal>
        <AnimatePresence>
          <div className="fixed inset-0 z-[999] overflow-y-auto">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4, ease: 'easeOut' }}
            >
              <AlertDialogPrimitive.Overlay className="fixed inset-0 bg-slate-500 !bg-opacity-75 transition-opacity" />
            </motion.div>

            <motion.div
              className="grid h-screen w-screen place-items-center p-4 sm:p-0"
              initial={{ opacity: 0, scale: 0.9, y: -2 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.9, y: -2 }}
              transition={{ duration: 0.4, ease: 'anticipate' }}
            >
              <AlertDialogPrimitive.Content
                className={clsx(
                  className,
                  'relative inline-block transform space-y-3 overflow-hidden rounded-xl bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-4 sm:pt-3 sm:align-middle',
                )}
              >
                <div className="flex flex-col gap-1.5">
                  <div className="flex flex-nowrap justify-between">
                    <AlertDialogPrimitive.Title asChild>
                      <Heading level={4}>{title}</Heading>
                    </AlertDialogPrimitive.Title>
                  </div>
                  {description && (
                    <AlertDialogPrimitive.Description aria-roledescription={description} asChild>
                      <Text type="secondary">{description}</Text>
                    </AlertDialogPrimitive.Description>
                  )}
                </div>

                <div className="flex flex-row-reverse gap-3">
                  <div className="flex justify-end">
                    <AlertDialogPrimitive.Action asChild type={undefined}>
                      <Button onClick={action.onClick} icon={action.icon} size="sm" destructive>
                        {action.label}
                      </Button>
                    </AlertDialogPrimitive.Action>
                  </div>
                  <AlertDialogPrimitive.Cancel asChild type={undefined}>
                    <Button theme="white" size="sm">
                      {t.cancel}
                    </Button>
                  </AlertDialogPrimitive.Cancel>
                </div>
              </AlertDialogPrimitive.Content>
            </motion.div>
          </div>
        </AnimatePresence>
      </AlertDialogPrimitive.Portal>
    </AlertDialogPrimitive.Root>
  );
};
