import withApollo from 'next-with-apollo';
import { defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { i18n } from 'i18n';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import { parseCookies } from 'nookies';
import getConfig from 'next/config';
import resolvers from '../graphql/client/resolvers';

const { publicRuntimeConfig } = getConfig();
const { graphqlHost } = publicRuntimeConfig;

// TODO: update it as we need
const initialState = {
  // update the init state of client after
  // sideBar: {
  //   __typename: 'sideBar',
  //   sideBar: true,
  // },
};

const isServer = typeof window === 'undefined';
// eslint-disable-next-line no-underscore-dangle
const hydratedData = !isServer ? window?.__NEXT_DATA__?.hydratedData : {};

export const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    // eslint-disable-next-line no-underscore-dangle
    switch (object.__typename) {
      case 'Cart':
        return `Cart:${object.userId}`;
      case 'Item':
        return `Item:${object.subId ? `${object.productId}-${object.subId}` : object.productId}`;
      case 'OrderItem':
        return `OrderItem:${
          object.subId ? `${object.productId}-${object.subId}` : object.productId
        }`;
      case 'GoogleAccountsGroup':
        return `GoogleAccountsGroup:${object.networkReferer}`;
      default:
        return defaultDataIdFromObject(object);
    }
  },
}).restore(hydratedData || {});

export function createClient() {
  const authMiddleware = new ApolloLink((operation, forward) => {
    const { jwToken } = parseCookies();

    operation.setContext(() => {
      return {
        fetchOptions: {
          credentials: 'include',
        },
        headers: {
          ...(jwToken ? { authorization: `Bearer ${jwToken}` } : {}),
          lang: i18n.language,
          'X-Frame-Options': 'sameorigin',
        },
      };
    });

    return forward(operation);
  });
  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([
      authMiddleware,
      createUploadLink({
        uri: graphqlHost || 'http://localhost:4000/graphql',
        'X-Frame-Options': 'sameorigin',
      }),
    ]),
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          // eslint-disable-next-line no-console
          console.error(JSON.stringify(error));
        });
      }

      if (networkError) {
        // eslint-disable-next-line no-console
        console.error(JSON.stringify(networkError));
      }
    },
    resolvers,
  });

  client.onResetStore(async () => cache.writeData({ data: initialState }));

  return client;
}

export default withApollo(createClient);
