const path = require('path');
const NextI18Next = require('next-i18next').default;
const { localeSubpaths } = require('next/config').default().publicRuntimeConfig;

module.exports = new NextI18Next({
  localePath: path.resolve(`./public/translations/locales`),
  defaultLanguage: 'fr',
  otherLanguages: ['en', 'es', 'it', 'nl', 'de', 'cimode'],
  localeSubpaths,
  detection: {
    lookupCookie: 'next-i18next',
    order: ['cookie', 'querystring', 'localStorage', 'path', 'subdomain'],
    caches: ['cookie'],
    cookieOptions: { secure: true },
  },
});
