import clsx from 'clsx';
import React from 'react';
import { ButtonProperties } from './button.types';

const typeVariants: Record<Exclude<ButtonProperties['theme'], undefined>, string> = {
  primary:
    'inline-flex items-center border border-solid border-transparent font-medium shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
  secondary:
    'inline-flex items-center border border-solid border-transparent font-medium text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
  white:
    'inline-flex items-center border border-solid border-slate-300 shadow-sm font-medium text-slate-700 bg-white hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
  link: 'inline-flex items-center cursor-pointer underline text-primary-600 !p-0 hover:brightness-90',
};

const iconColorVariants: Record<Exclude<ButtonProperties['theme'], undefined>, string> = {
  primary: 'text-white',
  secondary: 'text-primary-700',
  white: 'text-slate-700',
  link: 'text-primary-600',
};

const sizeVariants: Record<
  Exclude<ButtonProperties['size'], undefined>,
  {
    withText: string;
    withoutText: string;
  }
> = {
  xs: {
    withText: 'px-2.5 py-1.5 text-xs rounded',
    withoutText: 'p-1 rounded',
  },
  sm: {
    withText: 'px-3 py-2 text-sm leading-4 rounded-md',
    withoutText: 'p-1.5 rounded-md',
  },
  base: {
    withText: 'px-4 py-2 text-sm rounded-md',
    withoutText: 'p-2 rounded-md',
  },
  l: {
    withText: 'px-4 py-2 text-base rounded-md',
    withoutText: 'p-2 rounded-md',
  },
  xl: {
    withText: 'px-6 py-3 text-base rounded-md',
    withoutText: 'p-3 rounded-md',
  },
};

const iconSizeVariants: Record<
  Exclude<ButtonProperties['size'], undefined>,
  {
    withText: string;
    withoutText: string;
  }
> = {
  xs: {
    withText: '-ml-0.5 mr-1.5 w-3 h-3',
    withoutText: 'h-4 w-4',
  },
  sm: { withText: '-ml-0.5 mr-2 h-4 w-4', withoutText: 'h-5 w-5' },
  base: { withText: '-ml-1 mr-2 h-5 w-5', withoutText: 'h-5 w-5' },
  l: { withText: '-ml-1 mr-3 h-5 w-5', withoutText: 'h-6 w-6' },
  xl: { withText: '-ml-1 mr-3 h-5 w-5', withoutText: 'h-6 w-6' },
};

/**
 * A button component that can be used to trigger actions.
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=18%3A3057
 */
export const Button = ({
  children,
  theme = 'primary',
  size = 'base',
  disabled = false,
  onClick = () => undefined,
  destructive = false,
  loading = false,
  fullWidth = false,
  icon,
  iconPosition = 'leading',
  ...properties
}: ButtonProperties & JSX.IntrinsicElements['button']): JSX.Element => {
  return (
    <button
      className={clsx(
        typeVariants[theme],
        sizeVariants[size][icon && iconPosition === 'only' ? 'withoutText' : 'withText'],
        fullWidth ? 'w-full' : 'w-fit',
        {
          'opacity-50 hover:cursor-not-allowed': disabled,
          'bg-rose-600 text-white hover:bg-rose-700 focus:!ring-rose-500': destructive,
        },
        icon && iconPosition === 'only' && 'flex items-center justify-center',
        'font-body ring-offset-white transition-all',
      )}
      disabled={disabled}
      onClick={(event) => {
        if (disabled || loading) {
          event.preventDefault();

          return;
        }

        onClick?.(event);
      }}
      {...properties}
    >
      {icon && ['leading', 'only'].includes(iconPosition) && (
        <span
          className={clsx(
            iconSizeVariants[size][iconPosition === 'only' ? 'withoutText' : 'withText'],
            'flex-shrink-0',
            iconColorVariants[theme],
            destructive && 'text-white',
          )}
        >
          {icon}
        </span>
      )}
      {children}
    </button>
  );
};
