// Path: packages/flex/src/textarea/textarea.tsx

import React from 'react';
import clsx from 'clsx';
import type { TextareaProperties } from './textarea.types';

/**
 *
 * A textarea is a multi-line text input control.
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=403%3A896&t=vo5C7EmV5hi4V2wo-1
 */
export const Textarea = ({
  onChange,
  placeholder,
  label,
  error,
  disabled,
  className,
  id,
  rows = 3,
  inputRef,
  ...properties
}: TextareaProperties & React.HTMLAttributes<HTMLTextAreaElement>) => {
  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-slate-700 text-slate-300"
        >
          {label}
        </label>
      )}
      <div className={clsx(label && 'mt-1')}>
        <textarea
          rows={rows}
          name={id}
          id={id}
          className={clsx(
            /* "form-textarea transition-all shadow-sm  block w-full sm:text-sm rounded-md",
            error
              ? "focus:ring-red-500 focus:border-red-500 border-red-300"
              : "focus:ring-primary-500 focus:border-primary-500 border-slate-300" */
            'font-body form-textarea rounded-md border border-solid text-sm text-slate-900 outline-none transition-all',
            'focus:ring-primary-600 ring-offset-white focus:ring-2 focus:ring-offset-2',
            disabled && '!cursor-not-allowed !opacity-50',
            error
              ? '!border-rose-600 focus-within:!ring-rose-600 focus:border-slate-300'
              : 'border-slate-300',
            className,
          )}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          ref={inputRef}
          {...properties}
        />
      </div>
    </div>
  );
};
