import React from 'react';
import Skeleton from './GenerateSkeleton';

const CatalogSkeleton = () => (
  <div className="flex h-full w-full flex-col gap-8">
    <Skeleton height={274} width="100%" />
    {[...Array(4).keys()].map((id) => (
      <div key={id} className="flex w-full gap-4 p-4">
        <div className="flex w-full flex-col gap-4" key={id}>
          <Skeleton height={50} width="90%" />
          <Skeleton height={80} width="60%" />
          <Skeleton height={20} width={100} />
        </div>
        <Skeleton height={200} width={200} />
      </div>
    ))}
  </div>
);

CatalogSkeleton.defaultProps = {};

CatalogSkeleton.propTypes = {};

export default CatalogSkeleton;
