export const size = {
  xsmall: '360',
  small: '600',
  medium: '840',
  large: '1024',
  xlarge: '1280',
  xxlarge: '1440',
  xxxlarge: '1920',
};

const devices = {
  xsmall: `(max-width: ${size.xsmall - 1}px)` /* xsmall */,
  mobile: `(min-width: ${size.xsmall}px) and (max-width:${
    size.small - 1
  }px)` /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */,
  tablet: `(min-width: ${size.small}px) and (max-width: ${
    size.medium - 1
  }px)` /* portrait tablets, landscape iPhone, smartphones, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */,
  tabletL: `(min-width: ${size.medium}px) and (max-width: ${
    size.large - 1
  }px)` /* tablet, landscape iPad, lo-res laptops */,
  desktop: `(min-width: ${size.large}px) and (max-width: ${
    size.xlarge - 1
  }px)` /* big landscape tablets, laptops, and desktops */,
  desktopL: `(min-width: ${size.xlarge}px)` /* hi-res laptops and desktops */,
  desktopXL: `(min-width: ${size.xxlarge}px)`,
  desktopXXL: `(min-width: ${size.xxxlarge}px)`,
  overSize: `(max-width: ${size.xxxlarge}px)`,
};

export default devices;
