/* eslint-disable react/button-has-type */
import React from 'react';
import { ArrowLongRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import type { CalloutProperties } from './callout.types';
import { Island } from '../Island';
import { cn } from '../Utils/classnames';

const colorVariants: Record<Exclude<CalloutProperties['color'], undefined>, string> = {
  rose: 'bg-rose-50 text-rose-700',
  indigo: 'bg-indigo-50 text-indigo-700',
  emerald: 'bg-emerald-50 text-emerald-700',
  amber: 'bg-amber-50 text-amber-700',
};

const sizeVariants: Record<Exclude<CalloutProperties['size'], undefined>, string> = {
  small: 'rounded-full',
  large: 'rounded-md',
};

const shadowVariants: Record<Exclude<CalloutProperties['color'], undefined>, string> = {
  rose: '!shadow-rose-900/20',
  indigo: '!shadow-indigo-900/20',
  emerald: '!shadow-emerald-900/20',
  amber: '!shadow-amber-900/20',
};

/**
 *
 * A callout is a small piece of content that is used to highlight a piece of information.
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=108%3A2107
 */
export const Callout = ({
  color = 'emerald',
  size = 'small',
  islandLabel,
  actionnable,
  actionLabel,
  onClick,
  dismissible,
  dismissLabel,
  onDismiss,
  children,
  className,
}: CalloutProperties): JSX.Element => {
  const determineLayout = () => {
    if (size === 'large' && dismissible && actionnable) return 'flex-row items-start';
    if (size === 'large' && actionnable) return 'flex-col';
    if (size === 'large' && dismissible) return 'flex-row items-start';
    if (size === 'small' && actionnable) return 'flex-row items-center';
    if (size === 'small' && dismissible) return 'flex-row items-center';

    return 'flex-row';
  };

  return (
    <div
      className={cn(
        'flex gap-2 px-4 py-2 font-body text-sm shadow-md',
        colorVariants[color],
        sizeVariants[size],
        className,
        shadowVariants[color],
        { small: 'items-center', large: 'items-start' }[size],
      )}
    >
      {islandLabel && (
        <div className={cn('-ml-2 shadow-sm', shadowVariants[color], sizeVariants[size])}>
          <Island
            color={color}
            size="small"
            className="font-semibold"
            type={size === 'large' ? 'square' : 'pill'}
          >
            {islandLabel}
          </Island>
        </div>
      )}

      <div className={cn('flex gap-1', determineLayout())}>
        <div className={cn(size === 'large' && actionnable && dismissible ? 'block' : 'contents')}>
          <div className={cn(typeof children === 'string' && 'text-[13px]/[18px]')}>{children}</div>

          {actionnable && (
            <button
              onClick={onClick}
              className={cn(
                'inline-flex items-center gap-0.5 text-[13px]/[18px] font-semibold hover:underline',
                colorVariants[color],
              )}
            >
              {actionLabel}
              <ArrowLongRightIcon className="h-6 w-6" />
            </button>
          )}
        </div>

        {dismissible && (
          <button
            onClick={onDismiss}
            className="-m-2 inline-flex items-center gap-0.5 p-2 font-semibold hover:underline"
          >
            <span className="sr-only">{dismissLabel}</span>
            <XMarkIcon className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  );
};
