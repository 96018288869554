import { css } from 'styled-components';

const nprogressBar = css`
  /* nprogress loader */
  .bold {
    font-weight: bold;
  }

  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: var(--primary-500);
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.312rem;
  }

  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0rem;
    width: 6.25rem;
    height: 100%;
    box-shadow: 0 0 0.625rem var(--primary-500), 0 0 0.31rem var(--primary-500);
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0rem, -0.25rem);
    -ms-transform: rotate(3deg) translate(0rem, -0.25rem);
    transform: rotate(3deg) translate(0rem, -0.25rem);
  }

  #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 0.9375rem;
    right: 0.9375rem;
  }

  #nprogress .spinner-icon {
    width: 1.125rem;
    height: 1.125rem;
    box-sizing: border-box;
    border: solid 0.125rem transparent;
    border-top-color: var(--primary-500);
    border-left-color: var(--primary-500);
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  @-webkit-keyframes nprogress-spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default nprogressBar;
