import React from 'react';
import { useTranslation } from 'i18n';
import { DatePickerTranslatedProperties } from './datepicker.types';
import { DatePicker } from './datepicker';
import type { TDatePickerTranslationKeys } from './datepicker.types';

export function DatePickerTranslated({
  type = 'date',
  onChange,
  dateInputProps,
  rangeInputProps,
  min,
  max,
  className,
  calendarClassName,
  value,
  defaultValue,
  label,
  hint,
  optional,
  name,
}: DatePickerTranslatedProperties) {
  const { t } = useTranslation('flex');
  const translations: TDatePickerTranslationKeys = {
    optional: t('datepicker.optional'),
    thisYear: t('datepicker.thisYear'),
    lastYear: t('datepicker.lastYear'),
    allTime: t('datepicker.allTime'),
    last30Days: t('datepicker.last30Days'),
    last7Days: t('datepicker.last7Days'),
    lastMonth: t('datepicker.lastMonth'),
    lastWeek: t('datepicker.lastWeek'),
    nextMonth: t('datepicker.nextMonth'),
    previousMonth: t('datepicker.previousMonth'),
    thisMonth: t('datepicker.thisMonth'),
    period: t('datepicker.period'),
    today: t('datepicker.today'),
    tomorrow: t('datepicker.tomorrow'),
    yesterday: t('datepicker.yesterday'),
    selectDate: t('datepicker.selectDate'),
    selectDateRange: t('datepicker.selectDateRange'),
    days: t('datepicker.days', {
      returnObjects: true,
    }),
    months: t('datepicker.months', {
      returnObjects: true,
    }),
    lang: t('datepicker.lang'),
  };

  return (
    <DatePicker
      type={type}
      onChange={onChange}
      dateInputProps={dateInputProps}
      rangeInputProps={rangeInputProps}
      translations={translations}
      min={min}
      max={max}
      className={className}
      calendarClassName={calendarClassName}
      value={value}
      defaultValue={defaultValue}
      label={label}
      hint={hint}
      optional={optional}
      name={name}
    />
  );
}
