import { createGlobalStyle } from 'styled-components';
import reset from 'tokens/reset';
import nprogressBar from 'public/static/style';

const GlobalStyle = createGlobalStyle`
   ${reset}
   ${nprogressBar}
`;

export default GlobalStyle;
