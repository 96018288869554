// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import getConfig from 'next/config';
import * as Sentry from '@sentry/nextjs';

const { publicRuntimeConfig } = getConfig();

const { sentryDsn, sentryEnvironment = '', sentryRelease } = publicRuntimeConfig;

Sentry.init({
  dsn: sentryDsn,
  release: sentryRelease,
  environment: sentryEnvironment,
  debug: false,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  sampleRate: 1,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
