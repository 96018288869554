/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import omit from 'lodash/omit';
import shallowEqual from 'shallowequal';
import { colors } from 'tokens';
import DefaultContext from './context/DefaultContext';

class Provider extends PureComponent {
  static getConfig(props) {
    return omit(props, 'children', 'context', 'provider');
  }

  static bindTo(context) {
    return (props) => <Provider {...props} context={context} />;
  }

  constructor(props) {
    super(props);
    this.state = {
      config: Provider.getConfig(props),
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const currentConfig = Provider.getConfig(this.props);
    const newConfig = Provider.getConfig(nextProps);

    if (!shallowEqual(currentConfig, newConfig)) {
      this.setState({ config: newConfig });
    }
  }

  render() {
    const { children, context } = this.props;
    const { config } = this.state;

    const ContextProvider = context.Provider || context;

    return <ContextProvider value={config}>{children}</ContextProvider>;
  }
}

Provider.defaultProps = {
  theme: { colors },
  // User can provide the whole context object or just the Provider
  context: DefaultContext,
};

export default Provider;
