import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loaders from './components';

const componentNames = [
  'ThreeDots',
  'Puff',
  'Oval',
  'ProductSkeleton',
  'CatalogSkeleton',
  'FilterSkeleton',
  'HomePageSkeleton',
];

function componentName(type) {
  if (componentNames.includes(type)) {
    return Loaders[type];
  }

  return Loaders.Oval;
}

const LoaderWrapper = styled.div`
  display: flex;
  height: inherit;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`;

export default function Loader({ className, style, type, loading, ...rest }) {
  return loading ? (
    <LoaderWrapper style={style}>
      <div aria-busy="true" className={className}>
        {React.createElement(componentName(type), { ...rest })}
      </div>
    </LoaderWrapper>
  ) : null;
}

Loader.propTypes = {
  type: PropTypes.oneOf([...componentNames]),
  style: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

Loader.defaultProps = {
  type: 'Oval',
  style: {},
  className: '',
};
