import React from 'react';
import PropTypes from 'prop-types';
import addBindTo from '../../Provider/hocs/addBindTo';

const ThreeDots = ({ width, height, label, radius }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 120 30"
    xmlns="http://www.w3.org/2000/svg"
    fill="var(--primary-500)"
    aria-label={label}
  >
    <circle cx="15" cy="15" r={radius + 6}>
      <animate
        attributeName="r"
        from="15"
        to="15"
        begin="0s"
        dur="0.8s"
        values="15;9;15"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fillOpacity"
        from="1"
        to="1"
        begin="0s"
        dur="0.8s"
        values="1;.5;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="60" cy="15" r={radius} attributeName="fillOpacity" from="1" to="0.3">
      <animate
        attributeName="r"
        from="9"
        to="9"
        begin="0s"
        dur="0.8s"
        values="9;15;9"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fillOpacity"
        from="0.5"
        to="0.5"
        begin="0s"
        dur="0.8s"
        values=".5;1;.5"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="105" cy="15" r={radius + 6}>
      <animate
        attributeName="r"
        from="15"
        to="15"
        begin="0s"
        dur="0.8s"
        values="15;9;15"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fillOpacity"
        from="1"
        to="1"
        begin="0s"
        dur="0.8s"
        values="1;.5;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

ThreeDots.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  radius: PropTypes.number,
};

ThreeDots.defaultProps = {
  height: 80,
  width: 80,
  label: 'audio-loading',
  radius: 9,
};

export default addBindTo((context) => ({
  theme: context.theme,
}))(ThreeDots);
