import React, { useState } from 'react';
import { UserIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { AvatarProperties } from './avatar.types';

const sizeVariants: Record<Exclude<AvatarProperties['size'], undefined>, string> = {
  6: 'w-6 h-6 text-sm',
  8: 'w-8 h-8 text-sm',
  10: 'w-10 h-10 text-base',
  12: 'w-12 h-12 text-base',
  14: 'w-14 h-14 text-lg',
  16: 'w-16 h-16 text-lg',
};

const colorVariants: Record<
  Exclude<AvatarProperties['color'], undefined>,
  {
    bg: string;
    text: string;
    border: string;
  }
> = {
  slate: {
    bg: 'bg-slate-100',
    text: 'text-slate-400',
    border: 'border-slate-400',
  },
  rose: {
    bg: 'bg-rose-100',
    text: 'text-rose-400',
    border: 'border-rose-400',
  },
  indigo: {
    bg: 'bg-indigo-100',
    text: 'text-indigo-400',
    border: 'border-indigo-400',
  },
  emerald: {
    bg: 'bg-emerald-100',
    text: 'text-emerald-400',
    border: 'border-emerald-400',
  },
  amber: {
    bg: 'bg-amber-100',
    text: 'text-amber-400',
    border: 'border-amber-400',
  },
  brand: {
    bg: 'bg-primary-100',
    text: 'text-primary-400',
    border: 'border-primary-400',
  },
  gray: {
    bg: 'bg-gray-300',
    text: 'text-gray-400',
    border: 'border-gray-400',
  },
  red: {
    bg: 'bg-red-100',
    text: 'text-red-400',
    border: 'border-red-400',
  },
  yellow: {
    bg: 'bg-yellow-100',
    text: 'text-yellow-400',
    border: 'border-yellow-400',
  },
  green: {
    bg: 'bg-green-100',
    text: 'text-green-400',
    border: 'border-green-400',
  },
  blue: {
    bg: 'bg-blue-100',
    text: 'text-blue-400',
    border: 'border-blue-400',
  },
  purple: {
    bg: 'bg-purple-100',
    text: 'text-purple-400',
    border: 'border-purple-400',
  },
  pink: {
    bg: 'bg-pink-100',
    text: 'text-pink-400',
    border: 'border-pink-400',
  },
  primary: {
    bg: 'bg-primary-50',
    text: 'text-primary-400',
    border: 'border-primary-400',
  },
};

/**
 *
 * An Avatar component
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=46%3A570
 */
export const Avatar = ({
  size = 10,
  type = 'icon',
  initials,
  imageUri,
  imageProps,
  color = 'slate',
}: AvatarProperties): JSX.Element => {
  const [imageLoaded, setImageLoaded] = useState(!imageUri);

  const typeRenders = {
    icon: (
      <div
        className={clsx(
          'overflow-hidden rounded-full',
          sizeVariants[size],
          colorVariants[color].bg,
        )}
      >
        <UserIcon
          className={clsx('-ml-[5%] mt-[5%] h-[110%] w-[110%]', colorVariants[color].text)}
          aria-hidden="true"
        />
      </div>
    ),
    image: (
      <img
        className={clsx('inline-block rounded-full shadow-inner', sizeVariants[size])}
        src={imageUri}
        alt="Profile picture"
        onLoad={() => setImageLoaded(true)}
        {...imageProps}
      />
    ),
    initials: (
      <div
        className={clsx(
          'flex items-center justify-center overflow-hidden rounded-full border border-solid',
          sizeVariants[size],
          colorVariants[color].bg,
          colorVariants[color].border,
          'border-[0.5px] border-solid',
        )}
      >
        <span
          className={clsx('-mb-px font-semibold leading-none filter', colorVariants[color].text)}
        >
          {initials && initials?.length > 2 ? initials.slice(0, 2) : initials}
          {initials && initials?.length > 2 && (
            <span className="font-title text-xs font-normal">...</span>
          )}
        </span>
      </div>
    ),
  };

  return (
    <div
      className={clsx(
        'font-title h-min w-fit flex-shrink-0 overflow-hidden rounded-full shadow-inner',
        !imageLoaded && 'animate-pulse bg-slate-100',
      )}
    >
      {(type === 'image' && !imageUri) || (type === 'initials' && !initials)
        ? typeRenders.icon
        : typeRenders[type]}
    </div>
  );
};
