export enum ContentType {
  TextOnly = 'TextOnly',
  ButtonRedirect = 'ButtonRedirect',
}

export type SubActions = {
  [key: string]: string | number;
};

export type SubContent = {
  id: string;
  [key: string]: string | number | SubActions;
};

export type DataRow = {
  id: string;
  [key: string]: string | number | SubContent[] | undefined;
};

export type Column = {
  name: string;
  type: string;
  key: keyof DataRow;
};

export type FooterProps = {
  count: number;
  index: number;
  pageChange: (page: number) => void;
};

export type SortDirection = 'asc' | 'desc' | null;

export type Sort = {
  criteria: keyof DataRow;
  direction: SortDirection;
};

export type TableProps = FooterProps & {
  columns: Column[];
  data: DataRow[];
  noResultMessage: string;
  loading: boolean;
  sort: Sort;
  setSort: (sort: Sort) => void;
};
