// Path: packages/flex/src/Tooltip/Tooltip.tsx

import React from 'react';
import clsx from 'clsx';
import { Popover as PrimitiveTooltip } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useFloating, offset, autoUpdate } from '@floating-ui/react-dom';
import Text from '../Text';

import type { TooltipProperties } from './tooltip.types';

/**
 *
 * A popover is a floating panel that displays information related to an element when the element receives keyboard focus or the mouse hovers clicks it.
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=107%3A1164
 */
export const Tooltip = ({
  trigger,
  side = 'top',
  children,
  className,
}: TooltipProperties): JSX.Element => {
  const [visible, setVisible] = React.useState(false);

  const { x, y, refs, strategy } = useFloating({
    placement: side,
    strategy: 'fixed',
    middleware: [offset(6)],
    whileElementsMounted: autoUpdate,
  });

  const sideOffsets = {
    bottom: -2,
    top: 2,
    left: 2,
    right: -2,
  };

  return (
    <AnimatePresence>
      <PrimitiveTooltip className="relative">
        <div ref={refs.setReference}>
          <PrimitiveTooltip.Button>
            {React.cloneElement(trigger, {
              onMouseEnter: () => setVisible(true),
              onMouseLeave: () => setVisible(false),
            })}
          </PrimitiveTooltip.Button>
        </div>
        <AnimatePresence>
          {visible && (
            <PrimitiveTooltip.Panel
              ref={refs.setFloating}
              className={clsx('z-40 w-max max-w-sm', className)}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              }}
              static
            >
              <motion.div
                initial={{
                  opacity: 0,
                  y: ['top', 'bottom'].includes(side) ? sideOffsets[side] : 0,
                  x: ['left', 'right'].includes(side) ? sideOffsets[side] : 0,
                }}
                animate={{ opacity: 1, y: 0, x: 0 }}
                exit={{
                  opacity: 0,
                  y: ['top', 'bottom'].includes(side) ? sideOffsets[side] : 0,
                  x: ['left', 'right'].includes(side) ? sideOffsets[side] : 0,
                }}
                transition={{ duration: 0.2 }}
                className="text-body relative"
              >
                <div className="rounded-lg bg-white p-2 shadow-lg">
                  {typeof children === 'string' ? (
                    <Text className="secondary">{children}</Text>
                  ) : (
                    children
                  )}
                </div>
              </motion.div>
            </PrimitiveTooltip.Panel>
          )}
        </AnimatePresence>
      </PrimitiveTooltip>
    </AnimatePresence>
  );
};
