// Path: packages/flex/src/Input/Input.tsx

import React, { cloneElement } from 'react';
import clsx from 'clsx';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import useMeasure from 'react-use-measure';
import type { InputProperties } from './input.types';
import { Text } from '../Text/text';

const sizeVariants: Record<Exclude<InputProperties['sizing'], undefined>, string> = {
  default: 'px-3 py-2',
  small: 'px-2 py-1',
};

const trailingContainerVariants: Record<
  Exclude<
    InputProperties['trailingAddOnDisplay'] | InputProperties['leadingAddOnDisplay'],
    undefined
  >,
  string
> = {
  inline: 'text-slate-600 px-2',
  separate: 'border-gray-300 bg-slate-100 text-slate-500',
};

/**
 *
 * The Input component is used to collect user input.
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=19%3A5890&t=vo5C7EmV5hi4V2wo-1
 */
export const Input = ({
  placeholder,
  disabled,
  error = false,
  trailingAddOn,
  trailingAddOnDisplay = 'separate',
  leadingAddOn,
  leadingAddOnDisplay = 'separate',
  sizing = 'default',
  className,
  inputRef,
  name,
  ...properties
}: InputProperties &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >): JSX.Element => {
  const inputReference = React.useRef<HTMLInputElement>(null);

  const [addOnReference, addOnBounds] = useMeasure();

  const handleFocus = () => {
    inputReference.current?.focus();
    if (inputRef) {
      inputRef.current?.focus();
    }
  };

  return (
    <div
      className={clsx(
        'font-body relative flex items-stretch overflow-hidden rounded-md border border-solid bg-white transition-all',
        'focus-within:ring-primary-600 ring-offset-white focus-within:ring-2 focus-within:ring-offset-2',
        disabled && '!cursor-not-allowed !opacity-50',
        error
          ? 'border-rose-600 focus-within:border-slate-300 focus-within:ring-rose-600'
          : 'border-slate-300',
        className,
      )}
    >
      {leadingAddOn && (
        <div
          className={clsx(
            'flex items-center',
            trailingContainerVariants[leadingAddOnDisplay],
            leadingAddOnDisplay === 'separate' ? 'border-r' : '!pl-0',
          )}
          onClick={handleFocus}
        >
          {typeof leadingAddOn === 'string' ? (
            <Text type="secondary" className="leading-none">
              {leadingAddOn}
            </Text>
          ) : (
            cloneElement(leadingAddOn, {
              className: clsx('w-5 h-5', leadingAddOn.props.className, error && 'text-rose-500'),
            })
          )}
        </div>
      )}
      <input
        className={clsx(
          'form-calendar form-input block w-full border-none bg-transparent px-2 text-slate-900 outline-none transition-all focus:!shadow-none focus:!ring-0 sm:text-sm',
          {
            // error state
            'text-rose-600': error,
          },
          {
            // rounding
            'rounded-l-md': !leadingAddOn,
            'rounded-r-md': !trailingAddOn,
            'rounded-none': leadingAddOn && trailingAddOn,
          },
          'disabled:!cursor-not-allowed disabled:!opacity-50',
          sizeVariants[sizing],
        )}
        placeholder={placeholder}
        disabled={disabled}
        aria-invalid={error ? 'true' : undefined}
        ref={inputRef ?? inputReference}
        style={{
          // if there is a leading add-on, we need to adjust the padding
          // of the error icon to match the add-on's width
          paddingRight: error ? 16 + 20 : undefined,
        }}
        name={name}
        id={name}
        {...properties}
      />
      {trailingAddOn && (
        <div
          className={clsx(
            'flex items-center outline-none',
            {
              'text-rose-500': error,
            },
            trailingContainerVariants[trailingAddOnDisplay],
            trailingAddOnDisplay === 'separate' ? 'border-l' : 'border-none !pl-0',
          )}
          title={error && typeof error === 'string' ? error : undefined}
          onClick={handleFocus}
          ref={addOnReference}
        >
          {typeof trailingAddOn === 'string' ? (
            <Text className="leading-none">{trailingAddOn}</Text>
          ) : (
            cloneElement(trailingAddOn, {
              className: clsx('w-5 h-5', trailingAddOn.props.className, error && 'text-rose-500'),
            })
          )}
        </div>
      )}

      {error && (
        <div
          className="pointer-events-none absolute inset-y-0 flex items-center px-2"
          style={{
            right: trailingAddOn ? addOnBounds.width : 0,
          }}
        >
          <ExclamationCircleIcon className="h-5 w-5 text-rose-500" />
        </div>
      )}
    </div>
  );
};
