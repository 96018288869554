import React from 'react';
import PropTypes from 'prop-types';
import ProductSkeleton from './ProductSkeleton';
import Skeleton from './GenerateSkeleton';

const CatalogSkeleton = ({ count }) => (
  <div>
    <Skeleton height={20} width={200} style={{ margin: 20 }} />
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {Array(count)
        .fill({})
        .map((_, id) => id)
        .map((item) => (
          <ProductSkeleton key={item} />
        ))}
    </div>
  </div>
);

CatalogSkeleton.defaultProps = {
  count: 20,
};

CatalogSkeleton.propTypes = {
  count: PropTypes.number,
};

export default CatalogSkeleton;
