// Path: packages/flex/src/inputgroup/inputgroup.tsx

import React, { useMemo } from 'react';
import clsx from 'clsx';
import type { InputGroupProperties } from './inputgroup.types';
import { Input } from '../Input';
import { Text } from '../Text';

const defaultTranslationKeys = {
  optional: 'Optional',
};

export type TInputGroupTranslationKeys = Record<keyof typeof defaultTranslationKeys, string>;

/**
 *
 * The InputGroup component is a wrapper for the Input component, adding
 * additional functionality.
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=106%3A704
 */
export const InputGroup = ({
  label,
  hint,
  hintType = 'vertical',
  inputClassName,
  className,
  translations,
  error,
  ...properties
}: InputGroupProperties &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >): JSX.Element => {
  // merge the default translations with the user's translations if there are any
  const t: typeof defaultTranslationKeys = useMemo(
    () => ({
      ...defaultTranslationKeys,
      ...translations,
    }),
    [translations],
  );

  return (
    <div className={clsx('space-y-1', className)}>
      <div className="flex w-full items-center justify-between gap-4">
        <label htmlFor={properties.name} aria-label={label}>
          <Text as="span" className="truncate !font-medium" type={error ? 'danger' : 'default'}>
            {label}
          </Text>
        </label>

        {!properties.required && <Text type="muted">{t.optional}</Text>}
      </div>
      <div
        className={clsx(
          'flex gap-1',
          { vertical: 'flex-col', aside: 'flex-row-reverse' }[hintType],
        )}
      >
        <Input
          {...properties}
          className={inputClassName}
          error={error}
          aria-invalid={error ? 'true' : 'false'}
          aria-describedby={hint ? `${properties.name}-hint` : undefined}
        />
        {hint && !error && <Text type="muted">{hint}</Text>}

        {error && <Text type="danger">{error}</Text>}
      </div>
    </div>
  );
};
