const colors = {
  // Palette

  // Primary
  primary100: '#FFF7F7',
  primary200: '#FFE3E2',
  primary300: '#FFA7B7',
  primary400: '#FF708D',
  primary500: '#F03457', // PRIMARY PINK
  primary600: '#DB2C66',
  primary700: '#B81D5B',
  primary800: '#8A0012',
  primary900: '#690000',

  // Info
  info100: '#F2F8FF',
  info200: '#C7E2FF',
  info300: '#94CBFF',
  info400: '#42AAFF',
  info500: '#0095FF', // BLUE
  info600: '#006FD6',
  info700: '#0057C2',
  info800: '#0041A8',
  info900: '#002885',

  // Success
  success100: '#E3FFEF',
  success200: '#BCF8D6',
  success300: '#81ECAF',
  success400: '#37C173',
  success500: '#0EBA59', // GREEN
  success600: '#009538',
  success700: '#007216',
  success800: '#005000',
  success900: '#003100',

  // Warning
  warning100: '#FFFBF7',
  warning200: '#FFE7D6',
  warning300: '#FFCDA9',
  warning400: '#FFA969',
  warning500: '#FB7A1A', // ORANGE
  warning600: '#D15800',
  warning700: '#A83500',
  warning800: '#810D00',
  warning900: '#5E0000',

  // Danger
  danger100: '#FFF1F0',
  danger200: '#FEE1DF',
  danger300: '#FFB4AF',
  danger400: '#FF6B61',
  danger500: '#F44336', // RED
  danger600: '#D0191D',
  danger700: '#AC0002',
  danger800: '#890000',
  danger900: '#690000',

  // White
  white: '#FFFFFF', // WHITE
  white600: '#F7F9FC',
  white700: '#F2F5F8',
  white800: '#EDF1F7',

  black: '#000000', // BLACK

  // Grey
  grey100: '#FBFBFB',
  grey200: '#F7F7F7',
  grey300: '#F1F0F0',
  grey400: '#E6E6E6',
  grey500: '#CDCDCD', // GREY
  grey600: '#9D9D9D',
  grey700: '#707070',
  grey800: '#464646',
  grey900: '#202020',

  lightgrey: '#999694',

  // Text
  text100: '#D1D2E2',
  text200: '#A7A7B7',
  text300: '#7E7F8E',
  text400: '#585967',
  text500: '#343542',
  text600: '#272835',
  text700: '#1F202B',
  text800: '#191A25',
  text900: '#161620',

  // Labels

  radio: '#0f7070',
  presse: '#56b6af',
  video: '#3366ff',
  facebook: '#1778F2',
  twitter: '#0084B4',
  pinterest: '#C8232C',
  youtube: '#FF0000',
  linkedin: '#0A66C2',
  vimeo: '#1A2E3B',
  xing: '#006567',
  foursquare: '#E65778',

  // Others
  bronze: ' #7C6750',
  download: '#FFE180',
};

export default colors;
