import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { Maybe } from '@schema';
import type { CardProperties } from './card.types';

/**
 *
 * A card is a flexible and extensible content container.
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=19%3A1903
 */
export const Card = ({
  children,
  footer,
  header,
  footerSlate = false,
  narrow = false,
  well,
  className,
  actionRoute,
  isClickable = false,
}: CardProperties): JSX.Element => {
  const router = useRouter();

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (isClickable && actionRoute) {
      router.push(actionRoute);
    }
  };

  return (
    <div
      className={clsx(
        'flex cursor-pointer flex-col overflow-hidden rounded',
        well
          ? {
              basic: 'bg-slate-50',
              gray: 'bg-slate-200',
            }[well === true ? 'basic' : well]
          : 'bg-white shadow-md',

        className,
      )}
      onClick={(e) => handleCardClick(e)}
    >
      {header && !well && (
        <div
          className={clsx(
            'flex items-center justify-between border-b border-slate-200 [border-bottom-style:solid]',
            narrow ? 'p-3' : 'p-6',
          )}
        >
          {header}
        </div>
      )}
      <div className={clsx('flex flex-1 flex-col', narrow ? 'p-3' : 'p-6')}>{children}</div>
      {footer && !well && (
        <div
          className={clsx(
            'flex items-center justify-between border-t border-slate-200 [border-top-style:solid]',
            narrow ? 'p-3' : 'p-6',
            footerSlate && 'bg-slate-50',
          )}
        >
          {footer}
        </div>
      )}
    </div>
  );
};
