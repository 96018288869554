import React from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { CheckIcon, ChevronRightIcon, CircleStackIcon } from '@heroicons/react/24/outline';
import { cn } from '../Utils/classnames';

/**
 *
 * Menu that appears when a user interacts with a button, icon, or other control.
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=905%3A6190&t=JjVreTLNDf6GS4OS-1
 */
export const Dropdown: typeof DropdownMenuPrimitive.Root = DropdownMenuPrimitive.Root;

export const DropdownMenuTrigger: typeof DropdownMenuPrimitive.Trigger =
  DropdownMenuPrimitive.Trigger;

export const DropdownMenuGroup: typeof DropdownMenuPrimitive.Group = DropdownMenuPrimitive.Group;

export const DropdownMenuPortal: typeof DropdownMenuPrimitive.Portal = DropdownMenuPrimitive.Portal;

export const DropdownMenuSub: typeof DropdownMenuPrimitive.Sub = DropdownMenuPrimitive.Sub;

export const DropdownMenuRadioGroup: typeof DropdownMenuPrimitive.RadioGroup =
  DropdownMenuPrimitive.RadioGroup;

export const DropdownMenuSubTrigger: typeof DropdownMenuPrimitive.SubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    inset?: boolean;
  }
>(({ className, inset, children, ...properties }, reference) => (
  <DropdownMenuPrimitive.SubTrigger
    ref={reference}
    className={cn(
      'flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm font-medium outline-none focus:bg-slate-100 data-[state=open]:bg-slate-100',
      inset && 'pl-8',
      className,
    )}
    {...properties}
  >
    {children}
    <ChevronRightIcon className="ml-auto h-4 w-4" />
  </DropdownMenuPrimitive.SubTrigger>
));

DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;

export const DropdownMenuSubContent: typeof DropdownMenuPrimitive.SubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...properties }, reference) => (
  <DropdownMenuPrimitive.SubContent
    ref={reference}
    className={cn(
      'animate-in slide-in-from-left-1 z-50 min-w-[8rem] overflow-hidden rounded-md border border-slate-100 bg-white p-1 text-slate-700 shadow-md',
      className,
    )}
    {...properties}
  />
));

DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;

export const DropdownMenuContent: typeof DropdownMenuPrimitive.Content = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, ...properties }, reference) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={reference}
      sideOffset={sideOffset}
      className={cn(
        'animate-in data-[side=right]:slide-in-from-left-2 data-[side=left]:slide-in-from-right-2 data-[side=bottom]:slide-in-from-top-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-[8rem] overflow-hidden rounded-md border border-slate-100 bg-white p-1 text-slate-700 shadow-md',
        className,
      )}
      {...properties}
    />
  </DropdownMenuPrimitive.Portal>
));

DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

export const DropdownMenuItem: typeof DropdownMenuPrimitive.Item = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
  }
>(({ className, inset, ...properties }, reference) => (
  <DropdownMenuPrimitive.Item
    ref={reference}
    className={cn(
      'relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm font-medium outline-none focus:bg-slate-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      inset && 'pl-8',
      className,
    )}
    {...properties}
  />
));

DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

export const DropdownMenuCheckboxItem: typeof DropdownMenuPrimitive.CheckboxItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ className, children, checked, ...properties }, reference) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={reference}
    className={cn(
      'relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm font-medium outline-none focus:bg-slate-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    )}
    checked={checked}
    {...properties}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <CheckIcon className="h-4 w-4" />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.CheckboxItem>
));

DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;

export const DropdownMenuRadioItem: typeof DropdownMenuPrimitive.RadioItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem>
>(({ className, children, ...properties }, reference) => (
  <DropdownMenuPrimitive.RadioItem
    ref={reference}
    className={cn(
      'relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm font-medium outline-none focus:bg-slate-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    )}
    {...properties}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <CircleStackIcon className="h-2 w-2 fill-current" />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.RadioItem>
));

DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;

export const DropdownMenuLabel: typeof DropdownMenuPrimitive.Label = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean;
  }
>(({ className, inset, ...properties }, reference) => (
  <DropdownMenuPrimitive.Label
    ref={reference}
    className={cn('px-2 py-1.5 text-sm font-semibold text-slate-900', inset && 'pl-8', className)}
    {...properties}
  />
));

DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

export const DropdownMenuSeparator: typeof DropdownMenuPrimitive.Separator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...properties }, reference) => (
  <DropdownMenuPrimitive.Separator
    ref={reference}
    className={cn('-mx-1 my-1 h-px bg-slate-100', className)}
    {...properties}
  />
));

DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

export const DropdownMenuShortcut: React.FC<React.HTMLAttributes<HTMLSpanElement>> = ({
  className,
  ...properties
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn('ml-auto text-xs tracking-widest text-slate-500', className)}
      {...properties}
    />
  );
};

DropdownMenuShortcut.displayName = 'DropdownMenuShortcut';

const DropdownMenu: {
  Dropdown: typeof DropdownMenuPrimitive.Root;
  DropdownMenuTrigger: typeof DropdownMenuPrimitive.Trigger;
  DropdownMenuContent: typeof DropdownMenuContent;
  DropdownMenuItem: typeof DropdownMenuItem;
  DropdownMenuCheckboxItem: typeof DropdownMenuCheckboxItem;
  DropdownMenuRadioItem: typeof DropdownMenuRadioItem;
  DropdownMenuLabel: typeof DropdownMenuLabel;
  DropdownMenuSeparator: typeof DropdownMenuSeparator;
  DropdownMenuShortcut: typeof DropdownMenuShortcut;
  DropdownMenuGroup: typeof DropdownMenuPrimitive.Group;
  DropdownMenuPortal: typeof DropdownMenuPrimitive.Portal;
  DropdownMenuSub: typeof DropdownMenuPrimitive.Sub;
  DropdownMenuSubContent: typeof DropdownMenuPrimitive.SubContent;
  DropdownMenuSubTrigger: typeof DropdownMenuPrimitive.SubTrigger;
  DropdownMenuRadioGroup: typeof DropdownMenuPrimitive.RadioGroup;
} = {
  Dropdown,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuRadioGroup,
} as const;

const FlexDropdown: typeof DropdownMenu = DropdownMenu;

export default FlexDropdown;
