import React from 'react';
import Skeleton from './GenerateSkeleton';

const ProductSkeleton = () => (
  <div style={{ margin: 20 }}>
    <Skeleton height={200} width={200} style={{ marginBottom: 20 }} />
    <Skeleton count={2} height={40} width={200} style={{ marginBottom: 20 }} />
    <Skeleton count={1} height={20} width={200} style={{ margin: '40px 0' }} />
    <Skeleton count={1} height={40} width={200} />
  </div>
);

export default ProductSkeleton;
