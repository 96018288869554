import React from 'react';
import PropTypes from 'prop-types';
import { css, keyframes } from 'styled-components';

export const defaultBaseColor = '#eee';

export const defaultHighlightColor = '#f5f5f5';

export const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const skeletonStyles = css`
  background-color: ${defaultBaseColor};
  background-image: linear-gradient(
    90deg,
    ${defaultBaseColor},
    ${defaultHighlightColor},
    ${defaultBaseColor}
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
`;

export default function Skeleton({
  count,
  width,
  height,
  circle,
  className: customClassName,
  style: customStyle,
}) {
  const elements = [];

  for (let i = 0; i < count; i += 1) {
    const styleProps = {};

    if (width !== null) {
      styleProps.width = width;
    }

    if (height !== null) {
      styleProps.height = height;
    }

    if (width !== null && height !== null && circle) {
      styleProps.borderRadius = '50%';
    }

    let className = 'loading-skeleton';
    if (customClassName) {
      className += ` ${customClassName}`;
    }

    elements.push(
      <span
        key={i}
        className={className}
        css={css`
          ${skeletonStyles}
          animation: ${skeletonKeyframes} 1.2s ease-in-out infinite
        `}
        style={{ ...styleProps, ...customStyle }}
      >
        &zwnj;
      </span>,
    );
  }

  return <span style={{ display: 'flex', flexDirection: 'column' }}>{elements}</span>;
}

Skeleton.defaultProps = {
  count: 1,
  width: '100px',
  height: '10px',
  circle: false,
  className: '',
  style: {},
};

Skeleton.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  count: PropTypes.number,
  circle: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};
