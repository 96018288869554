/* eslint-disable unicorn/prevent-abbreviations */
// Path: packages/flex/src/timeline/timeline.tsx

import React from 'react';
import clsx from 'clsx';
import type { Event, TimelineProperties } from './timeline.types';
import { Text } from '../Text';

const colorVariants: Record<Event['iconBackground'], string> = {
  rose: 'bg-rose-500',
  indigo: 'bg-indigo-500',
  emerald: 'bg-emerald-500',
  amber: 'bg-amber-500',
  brand: 'bg-primary-500',
  slate: 'bg-slate-300',
};

/**
 *
 * A timeline is a list of events in chronological order.
 * @group Components
 */
export const Timeline = ({ events, className }: TimelineProperties) => {
  return (
    <div className={clsx('flow-root cursor-default', className)}>
      <ul role="list" className="-mb-8">
        {events.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== events.length - 1 ? (
                <span
                  className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-slate-200"
                  aria-hidden="true"
                />
              ) : undefined}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={clsx(
                      colorVariants[event.iconBackground],
                      'flex items-center justify-center rounded-full ring-8 ring-white/30',
                      event.icon ? 'h-8 w-8' : 'ml-2 mt-2 h-4 w-4',
                    )}
                  >
                    {event.icon && <event.icon className="h-5 w-5 text-white" aria-hidden="true" />}
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <Text type="secondary">
                      {event.content}
                      {event.target && (
                        <Text as="span" strong="medium" type="default">
                          {' '}
                          {event.target}
                        </Text>
                      )}
                    </Text>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-slate-500">
                    <time dateTime={event.datetime}>
                      <Text as="span" type="muted">
                        {event.date}
                      </Text>
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
