import React, { useEffect, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Text } from 'flex';
import { allRoutes } from 'components/UI/navigation.js';
import { withTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import useClickOutSide from 'components/hooks/useClickOutSide';
import { Formik } from 'formik';
import useSignout from '../hooks/useSignout';

export const strInStr = (str1, str2) =>
  str1 &&
  str2 &&
  str1
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
    .includes(
      str2
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .trim(),
    );

function CtrlK({ t }) {
  const router = useRouter();
  const inputRef = useRef(null);
  const clickRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const signOut = useSignout();

  useClickOutSide(() => setVisible(false), [inputRef, clickRef]);
  const initValues = {
    query: '',
    email: '',
  };

  const reducedRoutes = useMemo(
    () =>
      allRoutes.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.path]: `${acc[cur.path] || ''}${acc[cur.path] ? ' ' : ''}${t(
            `nav.${cur.i18n_value}`,
          )}`,
        }),
        {},
      ),
    [t],
  );

  useEffect(() => {
    if (visible) {
      inputRef.current?.focus();
    }
  }, [visible]);

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.code === 'Escape') setVisible(false);
      if (e.code === 'AltLeft' || e.code === 'MetaRight') setVisible(true);
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => document.removeEventListener('keydown', keyDownHandler);
  }, []);

  if (!visible) return null;

  const handleAutoLogin = (email, redirectTo) => {
    setVisible(false);
    const trueMail = email.includes('@') ? email : `${email}@yopmail.com`;
    router.push(`/autologin?email=${trueMail}&redirectTo=${redirectTo || router.asPath}`);
  };

  return (
    <div className="absolute inset-0 bg-gray-800 opacity-90 z-50 flex pt-60 items-center flex-col overflow-auto">
      <Formik initialValues={initValues}>
        {({ handleChange, values: { query, email } }) => {
          const options = Object.entries(reducedRoutes)
            .filter(([path, name]) => strInStr(path, query) || strInStr(name, query))
            .map(([value, label]) => ({
              value,
              label,
            }));

          return (
            <div className="relative w-1/3 flex flex-col gap-4 justify-center" ref={clickRef}>
              <div className="flex flex-col gap-4 justify-center transition-all">
                <div className="relative">
                  <Input
                    id="email"
                    fullWidth
                    name="email"
                    label="email"
                    className="opacity-70"
                    onKeyPress={(e) => {
                      if (e.charCode === 13) handleAutoLogin(email);
                    }}
                    trailingAddOn={email.includes('@') ? null : '@yopmail.com'}
                    value={email}
                    onChange={handleChange}
                  />
                  <div className="absolute -right-32 z-50 top-0 opacity-20 hover:opacity-80 gap-2 flex flex-col">
                    <Button
                      theme="secondary"
                      onClick={() =>
                        handleAutoLogin('hello@hyperspread.com', '/super-admin/organizations')
                      }
                    >
                      <Text>Super-Admin</Text>
                    </Button>
                    <Button
                      destructive
                      fullWidth
                      onClick={() => {
                        setVisible(false);
                        signOut();
                      }}
                    >
                      <Text>Sign-Out</Text>
                    </Button>
                  </div>
                </div>
              </div>

              <input
                ref={inputRef}
                type="text"
                id="query"
                className="w-full h-20 bg-white rounded shadow p-8 font-primary text-2xl"
                name="query"
                value={query}
                autoComplete="off"
                onChange={handleChange}
              />

              <div className="flex flex-col absolute top-40 w-full gap-1">
                {options.map((option) => (
                  <Button
                    key={option.value}
                    fullWidth
                    size="xl"
                    theme="secondary"
                    onClick={() => {
                      setVisible(false);
                      router.push(option.value);
                    }}
                  >
                    {option.label} ({option.value})
                  </Button>
                ))}
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

CtrlK.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('ui')(CtrlK);
