import LoginImages from './LoginImages';

export default {
  LoginImages,
  MetaValues: {
    title: 'Hyperspread',
    faviconPath: '/static/icons/favicon.ico',
    defaultCss: 'acuitis.css',
  },
  LogoPath: '/static/images/logos/logo-acuitis.svg',
  helpUrl: 'https://acuitis.help.hyperspread.com/',
};
