// Path: packages/flex/src/dialog/dialog.tsx

import React from 'react';
import clsx from 'clsx';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { AnimatePresence, motion } from 'framer-motion';
import type { DialogProperties } from './dialog.types';
import { Heading } from '../Heading';
import { Button } from '../Button';
import { Text } from '../Text';

/**
 *
 * A Dialog is a window overlaid on either the primary window or another dialog window.
 * @group Components
 */
export const Dialog = ({
  trigger,
  title,
  description,
  actions,
  onDismiss,
  className,
  children,
}: DialogProperties) => {
  return (
    <DialogPrimitive.Root
      onOpenChange={(open) => {
        if (!open && onDismiss) {
          onDismiss();
        }
      }}
    >
      <DialogPrimitive.Trigger asChild type={undefined}>
        {trigger}
      </DialogPrimitive.Trigger>
      <DialogPrimitive.Portal>
        <AnimatePresence>
          <div className="fixed inset-0 z-[999] overflow-y-auto">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4, ease: 'easeOut' }}
            >
              <DialogPrimitive.Overlay className="fixed inset-0 bg-slate-500 !bg-opacity-75 transition-opacity" />
            </motion.div>

            <motion.div
              className="grid h-screen w-screen place-items-center p-4 sm:p-0"
              initial={{ opacity: 0, scale: 0.9, y: -2 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.9, y: -2 }}
              transition={{ duration: 0.4, ease: 'anticipate' }}
            >
              <DialogPrimitive.Content
                className={clsx(
                  className,
                  'relative inline-block transform space-y-3 overflow-hidden rounded-xl bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-4 sm:pt-3 sm:align-middle',
                )}
              >
                <div className="flex flex-col gap-1.5">
                  <div className="flex flex-nowrap justify-between">
                    <DialogPrimitive.Title asChild>
                      <Heading level={4}>{title}</Heading>
                    </DialogPrimitive.Title>

                    <DialogPrimitive.Close asChild className="">
                      <XMarkIcon className="h-6 w-6 text-slate-500 hover:text-slate-600" />
                    </DialogPrimitive.Close>
                  </div>
                  {description && (
                    <DialogPrimitive.Description aria-roledescription={description} asChild>
                      <Text type="secondary" as="span">
                        {description}
                      </Text>
                    </DialogPrimitive.Description>
                  )}
                </div>

                <div className="my-4">{children}</div>

                <div className="!mt-4 flex flex-row-reverse gap-3">
                  {actions?.primary && (
                    <div className="flex justify-end">
                      <DialogPrimitive.Close asChild type={undefined}>
                        <Button
                          onClick={!actions.primary.disabled ? actions.primary.onClick : undefined}
                          icon={actions.primary.icon}
                          disabled={actions.primary.disabled}
                          size="sm"
                        >
                          {actions.primary.label}
                        </Button>
                      </DialogPrimitive.Close>
                    </div>
                  )}
                  {actions?.secondary &&
                    actions.secondary.map((action) => (
                      <DialogPrimitive.Close asChild type={undefined}>
                        <div className="flex justify-end" key={action.label}>
                          <Button
                            onClick={!action.disabled ? action.onClick : undefined}
                            theme="white"
                            icon={action.icon}
                            disabled={action.disabled}
                            size="sm"
                          >
                            {action.label}
                          </Button>
                        </div>
                      </DialogPrimitive.Close>
                    ))}
                </div>
              </DialogPrimitive.Content>
            </motion.div>
          </div>
        </AnimatePresence>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};
