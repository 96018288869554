// Path: packages/flex/src/Checkbox/Checkbox.tsx

import React from 'react';
import clsx from 'clsx';
import type { RadioProperties } from './radio.types';

/**
 *
 * A radio button is a form element that allows the user to select one option from a set of options.
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=34%3A835
 */
export const Radio = ({
  onChange,
  name,
  defaultChecked,
  disabled,
  className,
  value,
  checked,
  inputRef,
  ...properties
}: RadioProperties & React.HTMLAttributes<HTMLInputElement>): JSX.Element => {
  return (
    <input
      id={value}
      name={name}
      type="radio"
      className={clsx(
        'focus:ring-primary-500 text-primary-600 form-radio h-4 w-4 border-solid border-slate-300 bg-slate-100 ring-offset-white/50 transition-all',
        className,
      )}
      // the radio can be controlled or uncontrolled
      checked={checked || undefined}
      onChange={(event) => onChange?.(event)}
      disabled={disabled}
      value={value}
      defaultChecked={defaultChecked}
      ref={inputRef}
      {...properties}
    />
  );
};
