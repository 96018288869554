import { useEffect } from 'react';
import isFunc from 'utils/isFunc';

export default function useClickOutside(setState, refs) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (!refs) return;
      const clickOnRef = (!refs.length ? [refs] : refs).some(
        (ref) => ref.current && ref.current.contains(event.target),
      );

      if (!clickOnRef && isFunc(setState)) setState(false);
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs]);
}
