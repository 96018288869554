import { useApolloClient } from '@apollo/react-hooks';
import { signOut as ssoSignOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

export default function useSignout() {
  const client = useApolloClient();
  const router = useRouter();

  const signout = async ({ forceSuperAdminSignOut = false, redirectTo } = {}) => {
    try {
      await ssoSignOut({ redirect: false });
    } catch (e) {
      console.error(e);
    }

    const { superAdminJWT } = parseCookies();
    await destroyCookie(null, 'client_id', { path: '/' });
    await destroyCookie({}, 'superAdminJWT', { path: '/' });
    await destroyCookie({}, 'jwToken', { path: '/' });
    if (superAdminJWT && !forceSuperAdminSignOut) {
      setCookie(null, 'jwToken', superAdminJWT, { secure: true, path: '/' });
      setCookie(null, 'client_id', 'default', { secure: true, path: '/' });
    }

    await client.stop();
    await client.clearStore();

    if (redirectTo) router.replace(redirectTo);
    else {
      window.location = superAdminJWT ? '/super-admin/organizations' : '/';
    }
  };

  return signout;
}
