/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { IslandProperties } from './island.types';
import { Avatar } from '../Avatar';

const colorVariants: Record<
  Exclude<IslandProperties['color'], undefined>,
  {
    [keyof in Exclude<IslandProperties['appearance'], undefined>]: string;
  }
> = {
  slate: {
    default: 'bg-slate-100 text-slate-800 font-medium',
    outline: 'border border-solid border-[0.5px] border-slate-200 bg-transparent text-slate-300',
  },
  rose: {
    default: 'bg-rose-100 text-rose-800 font-medium',
    outline: 'border border-solid border-[0.5px] border-rose-200 bg-transparent text-rose-300',
  },
  indigo: {
    default: 'bg-indigo-100 text-indigo-800 font-medium',
    outline: 'border border-solid border-[0.5px] border-indigo-200 bg-transparent text-indigo-300',
  },
  emerald: {
    default: 'bg-emerald-100 text-emerald-800 font-medium',
    outline:
      'border border-solid border-[0.5px] border-emerald-200 bg-transparent text-emerald-300',
  },
  amber: {
    default: 'bg-amber-100 text-amber-800 font-medium',
    outline: 'border border-solid border-[0.5px] border-amber-200 bg-transparent text-amber-300',
  },
  brand: {
    default: 'bg-primary-100 text-primary-800 font-medium',
    outline:
      'border border-solid border-[0.5px] border-primary-200 bg-transparent text-primary-300',
  },
  gray: {
    default: 'bg-gray-300 text-gray-800 font-medium',
    outline: 'border border-solid border-[0.5px] border-gray-400 bg-transparent text-gray-400',
  },
  red: {
    default: 'bg-red-100 text-red-800 font-medium',
    outline: 'border border-solid border-[0.5px] border-red-400 bg-transparent text-red-400',
  },
  yellow: {
    default: 'bg-yellow-100 text-yellow-800 font-medium',
    outline: 'border border-solid border-[0.5px] border-yellow-400 bg-transparent text-yellow-400',
  },
  green: {
    default: 'bg-green-100 text-green-800 font-medium',
    outline: 'border border-solid border-[0.5px] border-green-400 bg-transparent text-green-400',
  },
  blue: {
    default: 'bg-blue-100 text-blue-800 font-medium',
    outline: 'border border-solid border-[0.5px] border-blue-400 bg-transparent text-blue-400',
  },
  purple: {
    default: 'bg-purple-100 text-purple-800 font-medium',
    outline: 'border border-solid border-[0.5px] border-purple-400 bg-transparent text-purple-400',
  },
  pink: {
    default: 'bg-pink-100 text-pink-800 font-medium',
    outline: 'border border-solid border-[0.5px] border-pink-400 bg-transparent text-pink-400',
  },
  primary: {
    default: 'bg-primary-50 text-primary-800 font-medium',
    outline:
      'border border-solid border-[0.5px] border-primary-400 bg-transparent text-primary-400',
  },
};

const sizeVariants: Record<Exclude<IslandProperties['size'], undefined>, string> = {
  small: 'px-2.5 py-1 text-xs',
  large: 'px-3 py-1 text-sm',
};

const typeVariants: Record<Exclude<IslandProperties['type'], undefined>, string> = {
  pill: 'rounded-full',
  square: 'rounded',
};

const dotVariants: Record<Exclude<IslandProperties['color'], undefined>, string> = {
  slate: 'bg-slate-400',
  rose: 'bg-rose-400',
  indigo: 'bg-indigo-400',
  emerald: 'bg-emerald-400',
  amber: 'bg-amber-400',
  brand: 'bg-primary-400',
  primary: 'bg-primary-400',
  blue: 'bg-blue-400',
  gray: 'bg-gray-400',
  green: 'bg-green-400',
  red: 'bg-red-400',
  yellow: 'bg-yellow-400',
  pink: 'bg-pink-400',
  purple: 'bg-purple-400',
};

const iconColorVariants: Record<Exclude<IslandProperties['color'], undefined>, string> = {
  slate: 'text-slate-400',
  rose: 'text-rose-400',
  indigo: 'text-indigo-400',
  emerald: 'text-emerald-400',
  amber: 'text-amber-400',
  brand: 'text-primary-400',
  primary: 'text-primary-400',
  blue: 'text-blue-400',
  gray: 'text-gray-400',
  green: 'text-green-400',
  red: 'text-red-400',
  yellow: 'text-yellow-400',
  pink: 'text-pink-400',
  purple: 'text-purple-400',
};

/**
 *
 * An Island component
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=19%3A11732
 */
export const Island = ({
  children,
  dismissible = false,
  onDismiss,
  dot = false,
  pingDot = false,
  imageUri,
  initials,
  color = 'slate',
  type = 'pill',
  appearance = 'default',
  size = 'small',
  className,
}: IslandProperties): JSX.Element => {
  return (
    <div
      className={clsx(
        'flex items-center gap-1.5 font-body',
        colorVariants[color][appearance],
        sizeVariants[size],
        typeVariants[type],
        className,
      )}
    >
      {dot && (!imageUri || !initials) && (
        <div className={clsx('relative h-1.5 w-1.5 rounded-full', dotVariants[color])}>
          {pingDot && (
            <div
              className={clsx(
                'absolute inset-0 h-1.5 w-1.5 animate-ping rounded-full',
                dotVariants[color],
              )}
            />
          )}
        </div>
      )}
      {(imageUri || initials) && (
        <div className="-ml-1.5">
          <Avatar
            size={size === 'small' ? 6 : 8}
            imageUri={imageUri}
            initials={imageUri ? undefined : initials}
            type={imageUri ? 'image' : initials ? 'initials' : undefined}
            color={color}
          />
        </div>
      )}
      {children}
      {dismissible && (
        <button
          className="flex h-4 w-4 items-center justify-center rounded-full hover:brightness-90"
          onClick={onDismiss}
        >
          <XCircleIcon className={iconColorVariants[color]} />
        </button>
      )}
    </div>
  );
};
