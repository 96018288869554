// Path: packages/flex/src/Stat/Stat.tsx

import React from 'react';
import clsx from 'clsx';
import { ArrowSmallDownIcon, ArrowSmallUpIcon } from '@heroicons/react/20/solid';
import type { StatProperties } from './stat.types';
import { Card } from '../Card';
import { Text } from '../Text';

/**
 *
 * Statistic cards are used to display detailed information about a single metric.
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=107%3A1524
 */
export const Stat = ({
  name,
  stat,
  previousStat,
  footer,
  change,
  changeType,
  disableFormatting = false,
  className,
}: StatProperties): JSX.Element => {
  return (
    <Card footer={footer} footerSlate>
      <div className={clsx('w-full', className)}>
        <Text as="dt" type="secondary" size="xs">
          {name}
        </Text>
        <dd className="mt-1 flex items-center justify-between gap-4">
          <div className="flex items-baseline font-semibold">
            <Text size="xl" strong="semi">
              {
                Number.isNaN(stat) || disableFormatting
                  ? stat.toString()
                  : Intl.NumberFormat(undefined, {
                      notation: 'compact',
                      compactDisplay: 'short',
                    }).format(stat as number) // because we know it's a number
              }
            </Text>
            {previousStat && (
              <Text size="xs" strong="semi" className="ml-2 font-medium !text-slate-500">
                {`vs. ${previousStat}`}
              </Text>
            )}
          </div>

          {change && changeType && (
            <div
              className={clsx(
                changeType === 'increase'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800',
                'inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium',
              )}
            >
              {changeType === 'increase' ? (
                <ArrowSmallUpIcon
                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                  aria-hidden="true"
                />
              ) : (
                <ArrowSmallDownIcon
                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                  aria-hidden="true"
                />
              )}

              <span className="sr-only">
                {changeType === 'increase' ? 'Increased' : 'Decreased'} by
              </span>
              <Text
                size="xs"
                strong="semi"
                className={changeType === 'increase' ? '!text-green-800' : '!text-red-800'}
              >
                {change.toString()}
              </Text>
            </div>
          )}
        </dd>
      </div>
    </Card>
  );
};
