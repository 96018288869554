import React from 'react';
import clsx from 'clsx';
import sanitizeHtml from 'sanitize-html';
import { TextProperties } from './text.types';

const sizeVariants: Record<Exclude<TextProperties['size'], undefined>, string> = {
  xs: 'text-[9px]/[12px]',
  sm: 'text-[11px]/[15px]',
  base: 'text-[13px]/[18px]',
  l: 'text-[16px]/[22px]',
  xl: 'text-[18px]/[25px]',
};

const colorVariants: Record<Exclude<TextProperties['type'], undefined>, string> = {
  default: 'text-slate-900',
  secondary: 'text-slate-700',
  muted: 'text-slate-500',
  success: 'text-emerald-600',
  warning: 'text-amber-600',
  danger: 'text-rose-600',
  brand: 'text-primary-600',
};

const strongVariants: Record<Exclude<TextProperties['strong'], undefined> | 'none', string> = {
  none: 'font-normal',
  medium: 'font-medium',
  semi: 'font-semibold',
  bold: 'font-bold',
};

/**
 * Typography component used to render body text.
 * @group Components
 */
export const Text = ({
  children,
  type = 'default',
  size = 'base',
  as = 'p',
  link = false,
  href,
  target,
  disabled = false,
  code = false,
  kbd = false,
  mark = false,
  strong,
  underline = false,
  italic = false,
  brow = false,
  className,
  style,
  title,
}: TextProperties): JSX.Element => {
  const Component = (() => {
    if (kbd) return 'kbd';
    if (code) return 'code';
    if (mark) return 'mark';
    if (link) return 'a';

    return as;
  })();

  const strongVariant = strong ?? (link ? 'semi' : 'none');

  // sanitize text
  const cleanText =
    typeof children === 'string' &&
    sanitizeHtml(children, {
      allowedTags: [],
      allowedAttributes: {},
      disallowedTagsMode: 'recursiveEscape',
    });

  const textContent = cleanText
    ? {
        __html: (kbd && typeof cleanText === 'string' && cleanText.length === 1
          ? // replace command or control with the appropriate symbol
            cleanText.replace(/(command)/gi, '⌘').replace(/ /gi, '')
          : cleanText
        )
          .replace(
            // replace text between backticks with code
            /`([^`]+)`/g,
            (match: string, p1: string) =>
              `<code class="inline font-mono px-1 py-0.5 text-[90%] bg-indigo-50 text-indigo-600 font-medium rounded">\`${p1}\`</code>`,
          )
          .replace(
            // if 'code' is true, wrap everything in backticks
            /(.+)/g,
            (match: string, p1: string) => (code ? `\`${p1}\`` : p1),
          ),
      }
    : undefined;

  return (
    <>
      <Component
        className={clsx(
          'font-body',
          link && 'cursor-pointer text-primary-600 underline hover:brightness-90',
          brow && 'text-[90%] font-semibold uppercase tracking-wider text-primary-900',
          !code && !brow && !kbd && strongVariants[strongVariant],
          !brow && sizeVariants[size],
          !link && !brow && colorVariants[type],
          underline && 'underline',
          italic && 'italic',
          disabled && 'cursor-not-allowed opacity-50',
          code && 'rounded bg-indigo-50 p-1 font-mono font-medium text-indigo-600',
          kbd && 'rounded bg-slate-100 px-1 font-mono font-black tracking-wider shadow',
          mark && 'rounded-sm bg-yellow-100 px-1',
          className,
        )}
        href={href}
        target={target}
        style={style}
        title={title}
        dangerouslySetInnerHTML={typeof children === 'string' ? textContent : undefined}
      >
        {typeof children === 'string' ? undefined : children}
      </Component>
    </>
  );
};
