import { connectContext } from 'react-connect-context-map';
import DefaultContext from '../context/DefaultContext';

const addBindTo = (mapContextToProps, mergeProps) => (Component) => {
  // eslint-disable-next-line no-param-reassign
  Component.bindTo = (Consumer) =>
    connectContext(Consumer, mapContextToProps, mergeProps)(Component);

  return Component.bindTo(DefaultContext.Consumer);
};

export default addBindTo;
