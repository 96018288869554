import ThreeDots from './ThreeDots';
import Puff from './Puff';
import Oval from './Oval';
import ProductSkeleton from './ProductSkeleton';
import CatalogSkeleton from './CatalogSkeleton';
import FilterSkeleton from './FilterSkeleton';
import HomePageSkeleton from './HomePageSkeleton';

const loaders = {
  ThreeDots,
  Puff,
  Oval,
  ProductSkeleton,
  CatalogSkeleton,
  FilterSkeleton,
  HomePageSkeleton,
};

export default loaders;
