// Path: packages/flex/src/Checkbox/Checkbox.tsx

import React from 'react';
import clsx from 'clsx';
import type { CheckboxProperties } from './checkbox.types';

/**
 *
 * A checkbox is a form element that allows the user to select one or more options from a set of options.
 * @group Components
 * @figma https://www.figma.com/file/0qfRwF3LsHjIT2hDLdySoS/%E2%98%81-flex?node-id=29%3A1681
 */
export const Checkbox = ({
  onChange,
  name,
  ariaDescribedBy,
  checked,
  disabled,
  className,
}: CheckboxProperties & React.HTMLAttributes<HTMLInputElement>): JSX.Element => {
  const [isChecked, setIsChecked] = React.useState(checked);

  return (
    <input
      id={name}
      aria-describedby={ariaDescribedBy}
      name={name}
      type="checkbox"
      className={clsx(
        'form-checkbox h-4 w-4 rounded border border-solid border-slate-300 bg-slate-100 text-primary-600 ring-offset-white/50 transition-all focus:ring-primary-500',
        className,
      )}
      // the checkbox can be controlled or uncontrolled
      checked={checked ?? isChecked}
      onChange={(event) => {
        onChange?.(event);
        setIsChecked(event.target.checked);
      }}
      disabled={disabled}
    />
  );
};
