// Path: packages/flex/src/pagination/pagination.tsx

import React, { useMemo } from 'react';
// import clsx from "clsx";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import type { PaginationProperties } from './pagination.types';

const defaultTranslationKeys = {
  previous: 'Previous',
  next: 'Next',
};

export type TPaginationTranslationKeys = Record<keyof typeof defaultTranslationKeys, string>;

const generate = (current: number, total: number) => {
  // generate pagination arwray
  const center = [current - 2, current - 1, current, current + 1, current + 2];
  const filteredCenter: (number | '...')[] = center.filter((p) => p > 1 && p < total);
  const includeThreeLeft = current === 5;
  const includeThreeRight = current === total - 4;
  const includeLeftDots = current > 5;
  const includeRightDots = current < total - 4;

  if (includeThreeLeft) filteredCenter.unshift(2);
  if (includeThreeRight) filteredCenter.push(total - 1);

  if (includeLeftDots) filteredCenter.unshift('...');
  if (includeRightDots) filteredCenter.push('...');

  return [1, ...filteredCenter, total];
};

const Item: Record<string, React.FC<{ onClick: () => void; page: number }>> = {
  Default: ({ onClick, page }) => (
    <button
      key={page}
      onClick={onClick}
      className="relative inline-flex items-center border border-solid border-slate-300 !bg-white px-4 py-2 text-sm font-medium text-slate-500 transition-colors hover:!bg-slate-50"
      type="button"
    >
      {page}
    </button>
  ),
  Active: ({ onClick, page }) => (
    <button
      key={page}
      onClick={onClick}
      className="relative inline-flex items-center border border-solid border-primary-700 bg-primary-600 px-4 py-2 text-sm font-medium text-white transition-colors"
      aria-current="page"
      type="button"
    >
      {page}
    </button>
  ),
};

const Fill = () => (
  <span className="relative inline-flex cursor-default items-center border border-solid border-slate-300 !bg-white px-4 py-2 text-sm font-medium text-slate-700">
    ...
  </span>
);

/**
 *
 * A pagination allows users to navigate through a set of pages.
 * @group Components
 */
export const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  translations,
}: PaginationProperties) => {
  // merge the default translations with the user's translations if there are any
  const t: typeof defaultTranslationKeys = useMemo(
    () => ({
      ...defaultTranslationKeys,
      ...translations,
    }),
    [translations],
  );

  const handlePageChange = (page: number) => {
    if (page < 1 || page > totalPages) {
      return;
    }

    onPageChange(page);
  };

  return (
    <motion.nav
      className="relative z-0 inline-flex -space-x-px rounded-md bg-white font-body shadow-sm"
      aria-label="Pagination"
    >
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        type="button"
        className="relative inline-flex items-center rounded-l-md border border-solid border-slate-300 bg-white px-2 py-2 text-sm font-medium text-slate-500 transition-colors hover:bg-slate-50"
      >
        <span className="sr-only">{t.previous}</span>
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
      </button>

      {generate(currentPage, totalPages).map((page) => {
        if (totalPages <= 1) return;

        if (page === '...') {
          return <Fill key={page} />;
        }

        if (page === currentPage) {
          return <Item.Active key={page} onClick={() => void 0} page={page} />;
        }

        return <Item.Default key={page} onClick={() => handlePageChange(page)} page={page} />;
      })}

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        type="button"
        className="relative inline-flex items-center rounded-r-md border border-solid border-slate-300 bg-white px-2 py-2 text-sm font-medium text-slate-500 hover:bg-slate-50"
      >
        <span className="sr-only">{t.next}</span>
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </motion.nav>
  );
};
