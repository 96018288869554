const spaces = {
  0: 0,
  1: '10px',
  2: '20px',
  3: '30px',
  4: '40px',
  5: '50px',
  6: '60px',
  7: '70px',
  8: '80px',
  9: '90px',
  10: '100px',
};

export default spaces;
